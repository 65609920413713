.page-game {
  overflow: hidden; /* Show vertical scrollbar */
}
.col-name:focus {
  outline: none !important;
}
#assets-card {
  height:420px;
  max-height:420px;
}
#liabilities-card, #equity-card {
  height:206px;
  max-height:206px;
}
#income, #expenses {
  height:250px;
  max-height:250px;
}
#row-two .card-body {
  min-height: 100px;
}
#row-one .col-one .card-body {
  min-height: 346px;
}
#row-one .col-two .card-body {
  min-height: 140px;
}
.game-card .card-body {
  overflow: auto;
  padding:0;
}
.game-card-body {
  text-align: justify;
}
#sidebar-header{
  background-color: $gray-400;
  min-height:150px;
}
#sidebar-body{
  background-color: $gray-500;
}
.bg-custom-primary {
  background-color: $primary; // #0165ba;
}
.bg-custom-warning {
  background-color: $warning; // #02ae7d;
}
#assets-card .game-col, #expenses .game-col {
  background-color: $primary; // #0165ba;
}
#assets-card .game-col .account-body, #expenses .game-col .account-body {
  background-color: #0165ba; // #0165ba;
}
#liabilities-card .game-col, #equity-card .game-col, #income .game-col {
  background-color: $warning; // #02ae7d;
}
#liabilities-card .game-col .account-body, #equity-card .game-col .account-body, #income .game-col .account-body {
  background-color: #02ae7d; // #02ae7d;
}
.bg-body-primary {
  background-color: #80b2dd;
}
.bg-body-warning {
  background-color: #81d6be;
}
.sidebar-brand-icon img {
  max-height: 100px;
}
.auth .col-lg-7 {
  min-height: 488px;
}
.alert-fixed {
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.2);
  z-index: 8000;
}
.alert-fixed i {
  width: 350px;
  margin-top:50vh;
  margin-left: 50vw;
  z-index: 8000;
}
.hidden {
  visibility: hidden;
  display: none;
}
.page-game .col-md-6 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.page-game .card-body {
  min-height:100%;
}
#transactionLabel {
  font-size: 1.5rem;
  font-weight: 600;
}
.page-game .border-primary {
  border-color: lighten($primary, 10%) !important;
}
.page-game .border-warning {
  border-color: lighten($warning, 10%) !important;
}
.page-game .card-body {
  padding: 0.65rem;
}
.page-game .active {
  border: 2px solid yellow !important;
  margin-bottom: 1px;
}
.ui-selected {
  border: 1px solid yellow !important;
  width:110px !important;
}
.ui-selected textarea,.account-wrapper, .ui-selected .account-footer {
  width: 110px !important;
}
.page-game .sidebar {
  width: 24rem !important;
}
.page-game .form-control-sm {
  padding: .25rem .5rem;
  font-size: .8rem;
  line-height: 1.1;
  border-radius: 0;
  border-bottom: unset;
}
.badge {
  z-index: 9;
  border: 1px solid white;
  width: 107px;
  cursor: pointer;
}
.bg-light-blue {
  background-color: lighten($primary, 20%);
}
.account-wrapper, .game-col {
  width: 110px;
  float:left;
}
.page-game .slot, .account-form .form-control-sm, .col-name, .account-footer {
  width: 110px;
  height: 28px;
  border-radius: 0;
}
.account-header textarea, .account-header textarea::placeholder {
  text-align:center;
  font-weight:600;
  background: transparent;
  color: white;
  border: 1px solid #e0e0e8;
  opacity:1;
}
.account-header textarea:focus {
  background: transparent;
  color: #fff;
}
.result-row {
  min-height:40px;
  border: #000 1px solid;
}
.bg-light {
  background-color: #f0f1f2 !important;
}
.fold {
  max-height: 0;
  overflow:hidden;
  padding-bottom: 0;
}
.unfold {
  max-height:100%;
  overflow: visible;
  padding-bottom: 20px;
}
.ui-draggable-dragging {
  pointer-events: none;
}
.account-footer {
  border: 1px solid #d1d3e2;
}
.hide {
  visibility: hidden;
}
#transactionLabel {
  min-height: 40px;
}
#date {
  min-height: 25px;
}
#description {
  min-height: 150px;
}
#pill-wrapper {
  min-height:60px;
}
#pill-wrapper .badge {
  margin-left:2px;
  margin-right: 2px;
}
.account-body {
  line-height:1.3;
  width:108px;
}
.page-game .card-body {
  padding-top: 0;
}
.note-heading {
  font-family: Pattaya, Fallback, sans-serif;
}
#transactionLabel {
  color: $success;
}
.ui-droppable-active {
  background-color: rgba(0,0,0,0.3);
  border: 1px dot-dot-dash !important;
}