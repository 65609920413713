.bg-password-image {
  background: url('http://127.0.0.1/img/ravlogotrans.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}


form.user {

  .custom-checkbox.small {
    label {
      line-height: 1.5rem;
    }
  }

  .form-control-user {
    font-size: 0.8rem;
    border-radius: 10rem;
    padding: 1.5rem 1rem;
  }

  .btn-user {
    font-size: 0.8rem;
    border-radius: 10rem;
    padding: 0.75rem 1rem;
  }

}

.btn-google {
  @include button-variant($brand-google, $white);
}

.btn-facebook {
  @include button-variant($brand-facebook, $white);
}
